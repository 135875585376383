import Repository from '@/libs/axios'

const resource = '/packagesubscription'
const defaultPagination = 20

export default {
  createAddon(payload) {
    return Repository.post(`${resource}/addons`, payload)
  },
  getAddonList(pageNumber = 1, pagination = defaultPagination, sort = '') {
    return Repository.get(`${resource}/addons?page=${pageNumber}&paginate=${pagination}${sort ? `&sort=${sort}` : ''}`)
  },
  updateAddon(payload, id) {
    return Repository.put(`${resource}/addons/${id}`, payload)
  },
  getAddonListNopagination() {
    return Repository.get(`${resource}/addons?noPagination`)
  },
}

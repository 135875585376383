import Repository from '@/libs/axios'

const auditResource = '/superadmin/tenants'

const defaultPagination = 10

export default {
  getAudits(tenantId, pageNumber = 1, filterQuery, pagination = defaultPagination, sort) {
    return Repository.get(`${auditResource}/${tenantId}/audit?${filterQuery}&page=${pageNumber}&paginate=${pagination}${sort ? `&sort=${sort}` : ''}`)
  },
  getAuditsNoPagination(tenantId, filterQuery) {
    return Repository.get(`${auditResource}/${tenantId}/audit?${filterQuery}&noPagination`)
  },
}

import Repository from '@/libs/axios'

const bannerResource = '/superadmin/tenants/notifications/banner'

const defaultPagination = 10

export default {
  getAllBannerList(filterQuery, pageNumber = 1, pagination = defaultPagination) {
    return Repository.get(`${bannerResource}?${filterQuery}&paginate=${pagination}&page=${pageNumber}`)
  },
  getAllBannerListNoPagination(filterQuery, sort) {
    return Repository.get(`${bannerResource}?sort=${sort}&${filterQuery}&noPagination`)
  },
  getBannerById(id) {
    return Repository.get(`${bannerResource}/get-banner-by-id/${id}`)
  },
  createBanner(payload) {
    return Repository.post(`${bannerResource}`, payload)
  },
  updateBanner(id, payload) {
    return Repository.put(`${bannerResource}/${id}`, payload)
  },
  deleteBanner(id) {
    return Repository.delete(`${bannerResource}/${id}`)
  },
}

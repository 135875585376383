import Repository from '@/libs/axios'

const resource = '/superadmin/package'
const packageResource = '/packagesubscription'

const defaultPagination = 10

export default {
  getPackageList(pageNumber = 1, filterQuery, pagination = defaultPagination, sort) {
    return Repository.get(`${packageResource}/packages?${filterQuery}&page=${pageNumber}&paginate=${pagination}${sort ? `&sort=${sort}` : ''}`)
  },
  getPackageListNoPagination(filterQuery) {
    return Repository.get(`${resource}?${filterQuery}&noPagination`)
  },
  getPackageDetails(id) {
    return Repository.get(`${packageResource}/packages/${id}`)
  },
  createPackage(payload) {
    return Repository.post(`${packageResource}/packages`, payload)
  },
  updatePackage(id, payload) {
    return Repository.put(`${packageResource}/packages/${id}`, payload)
  },
}

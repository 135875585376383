import Repository from '@/libs/axios'

const roleResource = '/superadmin/resource/roles'

export default {
  getStaffRoleListforDropdown(tenantid) {
    return Repository.get(`${roleResource}?filter[tenant_id]=${tenantid}`)
  },
  getRoleDetails(id) {
    return Repository.get(`${roleResource}/${id}`)
  },
  createRole(payload) {
    return Repository.post(`${roleResource}`, payload)
  },
  updateRole(id, payload) {
    return Repository.put(`${roleResource}/${id}`, payload)
  },
}

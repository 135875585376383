import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/clients',
      name: 'client-select',
      component: () => import('@/views/clientDashboard/ClientSelect.vue'),
      meta: {
        pageTitle: 'Client Select',
        breadcrumb: [
          {
            text: 'Client Select',
            active: true,
          },
        ],
      },
    },
    {
      path: '/client/:id',
      name: 'client-dashboard',
      component: () => import('@/views/clientDashboard/ClientDashboard.vue'),
      meta: {
        pageTitle: 'Client Dashboard',
        breadcrumb: [
          {
            text: 'Client Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/All-subscriptions',
      name: 'package-suscription',
      component: () => import('@/views/Subscriptions.vue'),
      meta: {
        pageTitle: 'All Subscriptions',
        breadcrumb: [
          {
            text: 'All Subscriptions',
            active: true,
          },
        ],
      },
    },
    {
      path: '/packages',
      name: 'all-packages',
      component: () => import('@/views/Packages.vue'),
      meta: {
        pageTitle: 'All Packages',
        breadcrumb: [
          {
            text: 'All Packages',
            active: true,
          },
        ],
      },
    },
    {
      path: '/addons',
      name: 'addons',
      component: () => import('@/views/Addons.vue'),
      meta: {
        pageTitle: 'Addons',
        breadcrumb: [
          {
            text: 'Addons',
            active: true,
          },
        ],
      },
    },
    {
      path: '/add-package',
      name: 'add-package',
      component: () => import('@/components/Packages/AddUpdatePackage.vue'),
      meta: {
        pageTitle: 'Add Package',
        breadcrumb: [
          {
            text: 'Add Package',
            active: true,
          },
        ],
      },
    },
    {
      path: '/add-subscription',
      name: 'add-subscription',
      component: () => import('@/components/Subscription/AddUpdateSubscription.vue'),
      meta: {
        pageTitle: 'Add Subscription',
        breadcrumb: [
          {
            text: 'Add Subscription',
            active: true,
          },
        ],
      },
    },
    {
      path: '/view-invoice/:id',
      name: 'view-invoice',
      component: () => import('@/components/Invoice/Invoice.vue'),
      meta: {
        pageTitle: '',
      },
    },
    {
      path: '/edit-package/:id',
      name: 'edit-package',
      component: () => import('@/components/Packages/AddUpdatePackage.vue'),
      meta: {
        pageTitle: 'Edit Package',
        breadcrumb: [
          {
            text: 'Edit Package',
            active: true,
          },
        ],
      },
    },
    {
      path: '/view-package/:id',
      name: 'view-package',
      component: () => import('@/components/Packages/AddUpdatePackage.vue'),
      meta: {
        pageTitle: 'View Package',
        breadcrumb: [
          {
            text: 'View Package',
            active: true,
          },
        ],
      },
    },
    {
      path: '/billing',
      name: 'billing',
      component: () => import('@/views/Billing.vue'),
      meta: {
        pageTitle: 'Billing',
        breadcrumb: [
          {
            text: 'Billing',
            active: true,
          },
        ],
      },
    },
    {
      path: '/activity-log',
      name: 'view-log',
      component: () => import('@/views/Log.vue'),
      meta: {
        pageTitle: 'Logs',
        breadcrumb: [
          {
            text: 'Activity Logs',
            active: true,
          },
        ],
      },
    },
    {
      path: '/registered-users',
      name: 'registered-users',
      component: () => import('@/views/RegisteredUsers.vue'),
      meta: {
        pageTitle: 'Registered Users',
        breadcrumb: [
          {
            text: 'User List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/clients-list',
      name: 'view-clients',
      component: () => import('@/views/AllClients.vue'),
      meta: {
        pageTitle: 'Client List',
        breadcrumb: [
          {
            text: 'Client List',
            active: true,
          },
        ],
      },
    },
    {
      path: '/manage-users',
      name: 'user-manage',
      component: () => import('@/views/UserManage.vue'),
      meta: {
        pageTitle: 'Manage Users',
        breadcrumb: [
          {
            text: 'Manage Users',
            active: true,
          },
        ],
      },
    },
    {
      path: '/manage-modules',
      name: 'module-manage',
      component: () => import('@/views/Modules.vue'),
      meta: {
        pageTitle: 'Manage Modules',
        breadcrumb: [
          {
            text: 'Manage Modules',
            active: true,
          },
        ],
      },
    },
    {
      path: '/client-alerts',
      name: 'client-alerts',
      component: () => import('@/views/Alerts.vue'),
      meta: {
        pageTitle: 'Client Alerts',
        breadcrumb: [
          {
            text: 'Send Alert',
            active: true,
          },
        ],
      },
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/Settings.vue'),
      meta: {
        pageTitle: 'Settings',
        breadcrumb: [
          {
            text: 'Settings',
            active: true,
          },
        ],
      },
    },
    {
      path: '/All-subscriptions/invoice',
      name: 'invoice',
      component: () => import('@/views/SubscriptionInvoice.vue'),
      meta: {
        pageTitle: 'Subscription Invoice',
        breadcrumb: [
          {
            text: 'Subscription Invoice',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
        noAuth: true,
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/forgot-password/ForgotPassword.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
        noAuth: true,
      },
    },
    {
      path: '/reset-password/:email/:token',
      name: 'reset-password',
      component: () => import('@/views/forgot-password/ResetPassword.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
        noAuth: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router

import Repository from '@/libs/axios'

const resource = '/superadmin/tenants'

const defaultPagination = 10

export default {
  getTenantUsers(tenantId, userType, pageNumber = 1, filterQuery, pagination = defaultPagination, sort) {
    return Repository.get(`${resource}/${tenantId}/user?${userType}&${filterQuery}&page=${pageNumber}&paginate=${pagination}${sort ? `&sort=${sort}` : ''}`)
  },
  getTenantUsersNoPagination(tenantId, userType, filterQuery) {
    return Repository.get(`${resource}/${tenantId}/user?${userType}&${filterQuery}`)
  },
  generateTemporaryPassword(tenantId, payload) {
    return Repository.post(`${resource}/${tenantId}/user/generate-temporary-password`, payload)
  },
}

import Repository from '@/libs/axios'

const billingResource = '/packagesubscription/subscription-invoice'
const subscriptionResource = '/packagesubscription'
const subcriptionNopagination = '/packagesubscription/subscription'
const approvePayment = 'packagesubscription/payment-approve'

const defaultPagination = 10

export default {
  getSubscriptions(pageNumber = 1, filterQuery, pagination = defaultPagination, sort) {
    return Repository.get(`${subscriptionResource}/subscription?${filterQuery}&page=${pageNumber}&paginate=${pagination}${sort ? `&sort=${sort}` : ''}`)
  },
  getSubscriptionsNoPagination() {
    return Repository.get(`${subcriptionNopagination}?&noPagination`)
  },
  createTenantSubscription(payload) {
    return Repository.post(`${subscriptionResource}/subscribe`, payload)
  },
  getInvoiceData(id) {
    return Repository.get(`${billingResource}/${id}`)
  },
  getBillingData(pageNumber = 1, filterQuery, pagination = defaultPagination, sort) {
    return Repository.get(`${billingResource}?${filterQuery}&page=${pageNumber}&paginate=${pagination}${sort ? `&sort=${sort}` : ''}`)
  },
  getBillingDetailsNoPagination() {
    return Repository.get(`${billingResource}?&noPagination`)
  },
  getPackageList() {
    return Repository.get(`${subscriptionResource}/packages`)
  },
  getPackageListNoPagination() {
    return Repository.get(`${subscriptionResource}/packages?&noPagination`)
  },
  getPaymentInvoiceById(id) {
    return Repository.get(`${billingResource}/${id}`)
  },
  approveSlip(id) {
    return Repository.post(`${approvePayment}/${id}`)
  },
}

import Repository from '@/libs/axios'

const orderStatsFinanceResource = '/merchant/dashboard/order-statistics'
const financialStatFinanceResource = '/merchant/dashboard/financial-statistics'
const orderMonthlyOrdersFinanceResource = '/merchant/dashboard/monthly-orders'
const orderMonthlyEarningsFinanceResource = '/merchant/dashboard/cod-income'
const orderCODReceivableFinanceResource = '/merchant/dashboard/cod-receivable'
const orderToBEInvoicesFinanceResource = '/merchant/dashboard/to-be-invoice'
const orderMerchantOnBoardingFinanceResource = '/merchant/dashboard/merchant-on-boarding'
const orderStatusStatisticsFinanceResource = '/merchant/dashboard/status-statistics'
const monthlyDeliveryChargeFinanceResource = '/merchant/dashboard/delivery-charges'

// const defaultPagination = 10
export default {
  getOrderStatsFinance() {
    return Repository.get(`${orderStatsFinanceResource}`)
  },
  getFinancialStatFinance() {
    return Repository.get(`${financialStatFinanceResource}`)
  },
  getMonthlyOrdersFinance() {
    return Repository.get(`${orderMonthlyOrdersFinanceResource}`)
  },
  getMonthlyEarningsFinance() {
    return Repository.get(`${orderMonthlyEarningsFinanceResource}`)
  },
  getCODRecivableFinance() {
    return Repository.get(`${orderCODReceivableFinanceResource}`)
  },
  getToBEInvoicesFinance() {
    return Repository.get(`${orderToBEInvoicesFinanceResource}`)
  },
  getMerchantOnBoardingFinance() {
    return Repository.get(`${orderMerchantOnBoardingFinanceResource}`)
  },
  getmonthlyDeliveryChargeFinance() {
    return Repository.get(`${monthlyDeliveryChargeFinanceResource}`)
  },
  getStatusStatisticsFinance() {
    return Repository.get(`${orderStatusStatisticsFinanceResource}`)
  },
}

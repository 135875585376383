import authRepository from './authRepository'
import resourceRepository from './resourceRepository'
import roleRepository from './roleRepository'
import dashBoardRepository from './dashBoardRepository'
import clientRepository from './clientRepository'
import registeredUserRepository from './registeredUserRepository'
import notificationRepository from './notificationRepository'
import seederRepository from './seederRepository'
import auditRepository from './auditRepository'
import packageRepository from './packageRepository'
import subscriptionRepository from './subscriptionRepository'
import tenantUserRepository from './tenantUserRepository'
import moduleRepository from './moduleRepository'
import addonRepository from './addonRepository'
import bannerRepository from './bannerRepository'
import merchantRepository from './merchantRepository'

const repositories = {
  auth: authRepository,
  resource: resourceRepository,
  role: roleRepository,
  dashboard: dashBoardRepository,
  client: clientRepository,
  registeredUser: registeredUserRepository,
  notification: notificationRepository,
  banner: bannerRepository,
  seeder: seederRepository,
  audit: auditRepository,
  package: packageRepository,
  subscription: subscriptionRepository,
  tenantUser: tenantUserRepository,
  module: moduleRepository,
  addon: addonRepository,
  merchant: merchantRepository,
}

// eslint-disable-next-line import/prefer-default-export
export const RepositoryFactory = {
  get: name => repositories[name],
}

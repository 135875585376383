import Repository from '@/libs/axios'

const clientResource = '/superadmin'

const defaultPagination = 10

export default {
  getUserList(pageNumber = 1, filterQuery, pagination = defaultPagination, sort) {
    return Repository.get(`${clientResource}/tenants?${filterQuery}&page=${pageNumber}&paginate=${pagination}${sort ? `&sort=${sort}` : ''}`)
  },
  getUserListNoPagination(filterQuery) {
    return Repository.get(`${clientResource}/tenants?${filterQuery}&noPagination`)
  },
  getUserListForDropdown() {
    return Repository.get(`${clientResource}/tenants?dropdown`)
  },
  getUserById(id) {
    return Repository.get(`${clientResource}/tenants/${id}`)
  },
  createUser(payload) {
    return Repository.post(`${clientResource}/tenants`, payload)
  },
  updateUser(id, payload) {
    return Repository.put(`${clientResource}/tenants/${id}`, payload)
  },
  updateUserStatus(payload) {
    return Repository.put(`${clientResource}/tenants/status-update`, payload)
  },
  getRegisterUserList(pageNumber = 1, filterQuery, pagination = defaultPagination, sort) {
    return Repository.get(`${clientResource}/tenants/admin/view-admin-users?${filterQuery}&page=${pageNumber}&paginate=${pagination}${sort ? `&sort=${sort}` : ''}`)
  },
  getRegisterUserListNoPagination(filterQuery) {
    return Repository.get(`${clientResource}/tenants/admin/view-admin-users?${filterQuery}&noPagination`)
  },
}

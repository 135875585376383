import Repository from '@/libs/axios'

const moduleResource = '/superadmin/module'

export default {
  getTenantModules(tenantId) {
    return Repository.get(`${moduleResource}?filter[tenant_id]=${tenantId}`)
  },
  updateTenantModule(payload) {
    return Repository.put(`${moduleResource}`, payload)
  },
}

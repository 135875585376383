import Repository from '@/libs/axios'

const resource = '/superadmin/tenants'

export default {
  getSeederList(filterQuery) {
    return Repository.get(`${resource}/get-seeders?${filterQuery}&noPagination`)
  },
  runDatabaseSeeder(tenantId) {
    return Repository.post(`${resource}/run-db-seeder/${tenantId}`)
  },
  runSelectedSeeders(tenantId, payload) {
    return Repository.post(`${resource}/run-selected-seeders/${tenantId}`, payload)
  },
}

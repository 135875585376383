import Vue from 'vue'
import Cookies from 'js-cookie'

// axios
import axios from 'axios'
import { apiUrl } from '@/constants/config'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'http://v1.localhost:8000/api/',
  baseURL: apiUrl,
})

axiosIns.interceptors.request.use(
  reqConfig => {
    const cacheUserToken = Cookies.get('current_user_token')
    if (cacheUserToken) {
      // eslint-disable-next-line no-param-reassign
      reqConfig.headers.Authorization = `Bearer ${cacheUserToken}`
    }
    return reqConfig
  },
  error => Promise.reject(error),
)

Vue.prototype.$http = axiosIns

export default axiosIns

import Cookies from 'js-cookie'
import axios from '@/libs/axios'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import router from '@/router/index'

const AuthRepository = RepositoryFactory.get('auth')

export default {
  state: {
    current_user_data: null,
  },
  mutations: {
    CLEAR_CURRENT_USER: state => {
      state.current_user_data = undefined
      state.current_user_permission = undefined
      Cookies.remove('current_user_token')
    },
    SET_CURRENT_USER: (state, value) => {
      state.current_user_data = value.user
      Cookies.set('current_user_token', value.token)
      axios.defaults.headers.common.Authorization = `Bearer ${value.token}`
    },
  },
  getters: {
    isLoggedIn: state => state.current_user_data !== null,
    currentUser: state => state.current_user_data,
  },
  actions: {
    setCurrentUser: ({ commit }, payload) => { commit('SET_CURRENT_USER', payload) },

    // eslint-disable-next-line no-unused-vars
    async afterLogin({ commit }, fromLogin) {
      if (!fromLogin) router.replace('/')
    },
    async autoLogin({ commit }) {
      try {
        // eslint-disable-next-line camelcase
        const cacheUserToken = Cookies.get('current_user_token')

        if (!this.getters.isLoggedIn && cacheUserToken) {
          let cacheUserData
          if (localStorage.getItem('current_user')) {
            cacheUserData = JSON.parse(localStorage.getItem('current_user'))
          } else {
            cacheUserData = (await AuthRepository.getCurrentUser()).data.data
            localStorage.setItem('current_user', JSON.stringify(cacheUserData))
          }
          commit('SET_CURRENT_USER', {
            user: cacheUserData,
            token: cacheUserToken,
          })
          await this.dispatch('afterLogin', true)
        }
      } catch (e) {
        await this.dispatch('logout')
      }
    },

    async adminLogin({ commit }, payload) {
      const loginData = (await AuthRepository.login(payload)).data
      commit('SET_CURRENT_USER', loginData)

      await this.dispatch('afterLogin')
    },

    async logout({ commit }) {
      // Cookies.remove('current_user_token')
      commit('CLEAR_CURRENT_USER')
      // await StaffAuthRepository.staffLogout()
      window.location = '/login'
    },

  },
}

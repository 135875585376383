import Repository from '@/libs/axios'

const clientResource = '/superadmin'

const defaultPagination = 10

export default {
  getClientList(pageNumber = 1, filterQuery, pagination = defaultPagination, sort) {
    return Repository.get(`${clientResource}/tenants?${filterQuery}&page=${pageNumber}&paginate=${pagination}${sort ? `&sort=${sort}` : ''}`)
  },
  getClientListNoPagination(filterQuery) {
    return Repository.get(`${clientResource}/tenants?${filterQuery}&noPagination`)
  },
  getClientListForDropdown() {
    return Repository.get(`${clientResource}/tenants?dropdown`)
  },
  getClientById(id) {
    return Repository.get(`${clientResource}/tenants/${id}`)
  },
  createClient(payload) {
    return Repository.post(`${clientResource}/tenants`, payload)
  },
  updateClient(id, payload) {
    return Repository.put(`${clientResource}/tenants/${id}`, payload)
  },
  updateClientStatus(payload) {
    return Repository.put(`${clientResource}/tenants/status-update`, payload)
  },
}

import Repository from '@/libs/axios'

const resource = '/superadmin'

export default {
  login(payload) {
    return Repository.post(`${resource}/login`, payload)
  },
  getPermissions() {
    return Repository.get(`${resource}/user/get-current-permissions`)
  },
  getCurrentUser() {
    return Repository.get(`${resource}/user/get-current`)
  },
  changeUserPassword(payload) {
    return Repository.post(`${resource}/change-password`, payload)
  },
  forgotPasswordEmailSubmit(payload) {
    return Repository.post(`${resource}/forgot-password`, payload)
  },
  forgotPasswordTokenVerify(token) {
    return Repository.get(`${resource}/forgot-password/${token}`)
  },
  forgotPasswordReset(payload) {
    return Repository.post(`${resource}/reset-password`, payload)
  },
}
